/*.background{
    position: absolute;
    top: 0;
    left: 0;
    /*width: 100vw;*/
 //   height: 120vh;
  //  width: 100%;
    //background: linear-gradient(210deg, #1D214E, #3a9cdd, #04D7A2);
    //background: conic-gradient(from 145deg at 50% 50%, #2e1771, #3e19cb, rgba(101,75,245,1));
//    background: linear-gradient(45deg, #5658fa, #6277fd, rgba(110,147,255,1));    
 //@at-root   background-size: 400% 400%;
  //  animation: gradient 5s ease infinite;
//}



.backgroundShadow{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,1) ;
        opacity: .7;
    }
.bg {
    z-index:-1;
    height: 99vh;
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; // Couvre toute la hauteur
      object-fit: cover; // Assure que l'image couvre toute la zone sans distorsion
      z-index: -1;
  }
  @media (max-width: 768px) { // Adjust the max-width value as needed for your target mobile devices
    height: 99vh; // Adjust the height of the container as needed
    img {
      height: 100%;
    }
  }
  }
  /*
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }*/
  
main{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #A4231C;
    position: absolute;
    left: 0;
    display: flex;
}

@media  (max-width: 1667px) {
    .background{
        height: 170vh;
    }
    @media (max-width: 1020px) {
        .background{
            height: 180vh;
        }
        @media (max-width:840px) {
            .background{
                height: 190vh;
            }
            @media (max-width:360px){
                .bg{
                    img{
                        object-fit: cover;
                    }
                }
                main{
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }
    }
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }
