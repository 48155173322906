.login-wrapper{
    margin-top: 10%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper{
        height: 100%;
        background-color: #ddd;
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        border-radius: 5px;
        padding: 50px 100px;
        .header{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            margin: 20px;
            font-size: 22px;
            border-bottom: 1px solid #000;
        }
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            input{
                margin: 10px;
                width: 200px;
                height: 33px;
                border: 1px #ccc solid;
                border-radius: 5px;
                &:focus{
                    outline: none;
                }
            }
        }
        .footer{
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                height: 35px;
                width: 130px;
                border-radius: 5px;
                border:  rgb(29, 29, 29) 1px solid;
                color: rgb(29, 29, 29);
                cursor: pointer;
                transition: .4s ease-in-out;
                &:hover{
                    background-color:  rgb(29, 29, 29);
                    color: #fff;
                }
            }
        }
    }
}