.dashboard{
    display: flex;
    position: absolute;
    height: 100%;
    
    .left-list{
        height: 100vh;
        height: 100%;
        background-color: rgb(240, 238, 238);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        border-radius: 5px;
        margin-top: 55px;
        position: fixed;
        ul{
            padding-left: 0;
            padding: 0 50px;
            li{
                text-decoration: none;
                list-style: none;
                margin: 36% 0;
                font-size: 18px;
                cursor: pointer;
                transition: .4s ease-in-out;
                color: #555;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:hover,&.selected{
                    color: #000;
                    border-bottom: 1px #999 solid;
                }
            }
        }
    }
    .content{
        margin-top: 100px;
        margin-left: 250px;
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}