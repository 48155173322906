.element-container{
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    margin: 20px 10px;
    position: relative;
    .element-content{
        display: flex;
        flex-direction: column;
        .image{
            cursor: pointer;
            .add_img_btn{
                position: absolute;
                top: 15px;
                right: 5px;
                z-index: 999;
                font-size: 22px;
            }
            .delete_img_btn{
                position: absolute;
                top: 15px;
                right: 65px;
                z-index: 999;
                font-size: 22px;
            }
            

            img,video{
                width: 500px;
                height: 300px;
                object-fit: cover;
                margin: 5px;
            }
        }
        .info{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                margin: 5px;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            }
        }
        .services-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            input{
                height: 33px;
                width: 260px;
                border: 1px #555 solid;
                border-radius: 5px;
                margin: 5px;
            }
        }
        .modifier{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                height: 43px;
                width: 150px;
                margin: 5px;
                cursor: pointer;
                border: #555 1px solid;
                border-radius: 4px;
                transition: .4s ease-in-out;
                &:hover{
                    background-color: #555;
                    color: #fafafa;
                    border: 2px #ccc solid;
                }
            }
        }
    }
}