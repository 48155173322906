.aboutus{
    //show image 
    background: linear-gradient(120deg, #f2f1f1, #f9f8f8, #ffffff); 
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    .showImg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        overflow: hidden;
        transition: .5s ease-in-out;
        .backgroundShowImg{
            position: fixed;
            top: 0;
            left: 0;
            background-color: #0b0b0b;
            width: 100%;
            height: 100%;
            opacity: .8;
        }
        .img{
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            transition: .4s ease-in-out;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            span{
                color: white;
                font-size: 2rem;
                cursor: pointer;
                align-self: flex-start;
                margin-top: 3%;
                z-index: 1;
            }
            img{
                position: absolute;
                max-width: 100%;
                max-height: 80%;
                width: 55%;
                height: auto;
                top: 50%;     
                left: 50%;
                transform: translate( -50%, -50%);
            }

        }
    }
    //scroll animation
    .reveal{
        position: relative;
        transform: translateY(0);
        opacity: 1;
        transition: 1s all ease;
        z-index: 9;
    }
      .reveal.active{
        transform: translateY(0);
        opacity: 1;
      }
    //about us image
    .aboutUsImg{
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            height: 100vh;
            width: 100vw;
            z-index: 9;
        }
    }
    .textForImg{
        position: relative;
        width: 100vw;
        left: -8px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background:linear-gradient(to bottom ,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,1) 100%);
        z-index: 10;
        p{
            font-size: 7rem;
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            color: rgb(251, 241, 241);
        }
        .arrowDown{
            position: absolute;
            bottom: 0;
            font-size: 2rem;
            color: white;
            animation-duration: 1s;
            animation-name: upToDown;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            transition: .5s ease-in-out;
        }
    }
    .arrowDown{
        &.scrolled{
                opacity: 0;
        }
    }

    .products{
        height: 100%;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(120deg, #f2f1f1, #f9f8f8, #ffffff); 
        .productsText{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70%;
            h4{
                font-weight: 200;
                font-size: 2.5rem;
            }   
            p{
                font-size: 1.5rem;
                width: 75%;
            }
            border-bottom: rgb(53, 53, 53) 2px solid;
            margin: 10px;
            //clip-path: ellipse(60% 100% at 49.4% 0%);
        }
        .productImgs{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            img{
                width: 33%;
                margin: 5px;
                cursor: pointer;
                transition: .3s ease-in-out;
                &:hover{
                    opacity: .5;
                }
            }
        }
        .productHistory{
            width: 50%;
            margin:30px 10px 20px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                font-size: 3.9rem;
                font-weight: 100;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

            }
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    width: 50%;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-size: 1rem;
                    margin: 25px;
                    font-weight: 100;
                }

            }
        }
    }
    /*.productFooter{
        width: 98vw;
        margin-left: 10px;
    }*/
}
@media (max-width: 1500px) {
    .aboutus{
        .textForImg{
            width: 99vw;
        }
    }
    @media (max-width:1100px){
        .aboutus{
            .textForImg{
                width: 98.9vw !important;
            }
            .products{
                width: 105.2% !important;
                .productsText{
                    text-align: center !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .productImgs{
                    img{
                        width: 45% !important;
                    }
                }
                .productHistory{
                    width: 90% !important ;
                }
            }
        }
        @media (max-width:1000px){
            .aboutus{
                width: 98% !important;
                .textForImg{
                    width: 100vw !important;
                }
            }
            @media (max-width:800px){
                .aboutus{
                    .textForImg{
                        width: 100vw !important;
                    }
                }
            }
                    @media (max-width:450px){
                        .aboutus{
                            margin-bottom: 900px;
                            .textForImg{
                                width: 99.9vw !important;
                            }
                            .products{
                                width: 103vw !important;
                                .productImgs{
                                    img{
                                        width: 90% !important;
                                    }
                                }
                                .productHistory{
                                    h2{
                                        font-size: 2rem;
                                    }
                                    div{
                                        p{
                                            font-size: 12px !important;
                                            margin: 10px;
                                        }
                                    }
                                }
                            }
                        
                        }
                        .textForImg{
                            width: 95.9vw !important;
                        }
                    }
        }
    }
}
@keyframes upToDown{
    from{
        bottom: 0;
        color: rgb(251, 241, 241);
    }
    to{
        bottom: 10px;
        color: rgb(251, 241, 241);
    }
}
