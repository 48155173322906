.single-service{
    z-index: 99;
    background-color: #f1f1f1;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    .single-service-content{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100vw;
        margin-top: 200px;
        .single-service-banner {
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80%;
            h1{
                font-family: 'Oswald', sans-serif;          
                color: rgb(255, 255, 255);
                border-bottom: 3px rgb(255, 255, 255) solid;
                font-size: 4rem;
                z-index: 20;
            }
            img{
                width: 100vw;
                position: absolute;
                height: 700px !important;
                left: 0;
                object-fit: cover;
            }
        }
        .content{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
            z-index: 99;
            background-color: #f1f1f1;
            padding-top: 20%;
            .products{
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                border-bottom: #ff7519 2px solid;
                padding-bottom: 3vh;
                h1{
                    padding-left: 100px;
                    font-family: 'Oswald', sans-serif;
                    color: #ff7519;            
                }
                .product-element{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 10px;
                    margin-left: 80px;
                    margin-bottom: 10px;
                    width: 50%;
                    border-bottom: #ccc 1px solid;
                    transition: .4s ease-in-out;
                    cursor: pointer;
                    &:hover,&.selected{
                        box-shadow: 0px 2px 4px rgb(109, 109, 109);
                        border-bottom: none;
                        svg{
                            opacity: 1;
                        }
                    }
                    &.selected{
                        box-shadow: 0px 3px 4px #ffa970;

                    }
                    h3{
                        font-size: 17px;
                        margin-left: 10px;
                        width: 200px;
                        color: black;
                    }
                    img{
                        max-height: 100px;
                        max-width: 100px;
                    }
                    svg{
                        opacity: 0;
                        height: 25px;
                        transition: .4s ease-in-out;
                    }
                }
            }
            .service{
                font-family: 'Roboto',sans-serif;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                p{
                    font-size: 1.1rem;
                    margin-bottom: 20px;
                }
                .description-service{
                    padding: 20px;
                    width: 80%;
                    font-weight: 300;
                    font-size: 1.5rem;

                }
                .resultat-trouve{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 90%;
                    select{
                        height: 50px;
                        background-color: rgb(241, 241, 241);
                        border: #ccc 1px solid;
                        border-radius: 4px;
                        color: rgb(75, 75, 75);
                        cursor: pointer;
                        option{
                            background-color:rgb(75, 75, 75) ;
                            color: rgb(241, 241, 241) ;
                        }
                    }
                }
                .elements{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    background-color: #f1f1f1;
                    .element{
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        margin: 20px ;
                        background-color: #f1f1f1;
                        width: 400px;
                        height: 500px;
                        .elt_data{
                            width: 100%;
                            height: 100%;
                            
                            img{
                                width: 100%;
                                height: 60%;
                                object-fit: cover;
                            }
                            p{
                                font-size: 16px;
                                color: #555;
                                padding: 10px;

                            }
                        }
                        .lire-la-suite{
                            opacity: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            background-color: rgba(0,0,0,1) ;
                            height: 100%;
                            width: 100%;
                            transition: .4s ease-in-out;
                        }
                        button{
                            height: 50px;
                            width: 180px;
                            cursor: pointer;
                            background-color: #fff;
                            border-radius: 5px;
                            border: none;
                            position: absolute;
                            z-index: 100;
                            opacity: 0;
                            transition: .4s ease-in-out;
                        }
                        &:hover{
                            .lire-la-suite{
                                opacity: .5;
                            }
                            button {
                                opacity: 1 !important;
                                &:hover{
                                    background: transparent;
                                    color: white;
                                    border: white 1px solid;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
   @media (max-width:1200px){
        .single-service{
            .single-service-content{
                .content{
                    .products{
                        width: 90%;
                        flex-direction: column;
                        h1{
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .product-element{
                            width: 100%;
                            margin-left: 0;
                        }
                    }
                }
                .service{
                    padding: 0 !important;
                    height: 98% !important;
                    >p{
                        margin: 10px;
                        padding-left: 10px;
                    }
                }
            }
        }
        @media (max-width:550px){
            .single-service{
                width: 101%;
                .single-service-content{
                    .single-service-banner{
                        h1{
                            margin-top: 20%;
                        }
                    }
                    .content{
                        padding-top: 0;
                        width: 100vw !important;
                        margin-left: -3px !important;
                    }
                }
            }
            @media(max-width:400px){
                .single-service{
                    .single-service-content{
                        margin-top: 50px;
                        .single-service-banner{
                            margin-left: 3px;
                            img{
                                width: 101vw !important;
                            }
                        }
                        .content{
                            margin-top: -13px;
                            margin-left: 3px !important;
                        }
                    }
                }
            }
        }
    }