.backgroundShadow{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,1) ;
    opacity: .7;
}
.contactUs{
    display: flex;
    flex-direction: column;

    .contact-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .contact-us-background{
        .arrows-wrapper{
            .contact-us-arrow-one{
                //background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 2'%3E%3Cpath opacity='0.15' d='M0 0v2L1 1z' fill='url(%23g)'/%3E%3ClinearGradient id='g' x1='0' x2='1' y1='1' y2='0'%3E%3Cstop offset='0%' stop-color='%230084b4'/%3E%3Cstop offset='100%' stop-color='%2352babb'/%3E%3C/linearGradient%3E%3C/svg%3E") no-repeat;
                width: 90vw;
                height: 150vh;
                position: absolute;
                top: 500px;
                left: 0;
                z-index: -1;
            }
            .contact-us-arrow-two{
                /*&::after{
                    content: ' ';
                    border-top: 500px solid #52babb;
                    border-left: 30vw solid transparent;
                    border-right: 30vw solid transparent;
                    position: absolute;
                    right: 0;
                    top: 80px;
                    z-index: -1;
                    opacity: .5;
                }*/
                //background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 2'%3E%3Cpath opacity='0.15' d='M0 0v2L1 1z' fill='url(%23g)'/%3E%3ClinearGradient id='g' x1='0' x2='1' y1='1' y2='0'%3E%3Cstop offset='0%' stop-color='%230084b4'/%3E%3Cstop offset='100%' stop-color='%2352babb'/%3E%3C/linearGradient%3E%3C/svg%3E") no-repeat;
                width: 80%;
                height: 150vh;
                position: absolute;
                transform: rotate(180deg);
                top: 100vh;
                right: 0%;
                overflow: hidden;  
                z-index: -1;     
            }
            .contact-us-arrow-three{
                //background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 2'%3E%3Cpath opacity='0.15' d='M0 0v2L1 1z' fill='url(%23g)'/%3E%3ClinearGradient id='g' x1='0' x2='1' y1='1' y2='0'%3E%3Cstop offset='0%' stop-color='%230084b4'/%3E%3Cstop offset='100%' stop-color='%2352babb'/%3E%3C/linearGradient%3E%3C/svg%3E") no-repeat;
                position: absolute;
                rotate: 180deg;
                top: 180%;
                left: 0%;
                overflow: hidden;
                z-index: -1;
                width: 80%;
                height: 100vh;
            }
        }
    }
    .content{
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        .header{
            justify-content: center;
            display: flex;
            .info{
                width: 50%;
                .title{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 100px;
                    font-family: 'Oswald', sans-serif;                    
                    color: #f1f1f1;
                    font-size: 33px;
                }
                .desc{
                    p{
                        font-size: 28px;
                        font-family: 'Oswald', sans-serif;
                        color: #555;
                        margin-top: 200px;
                        text-align: center;
                    }
                }
            }
            //position absolute
            .image{
                img{
                    width: 100%;
                    height: 500px;
                    border-radius: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -4;
                    
                }
            }
        }
        .map{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            .map{
                width: 55% !important;
            }
        }
        .horaires-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 5%;
            >p{
                font-size: 35px;
                font-family: "Oswald", sans-serif;
            }
            .horaires{
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(27, 23, 23);
                border-bottom: 1px #A4231C solid;
                font-family: "Oswald", sans-serif;
                font-size: 22px;
                width: 50%;
            }
        }
        .contacts-wrapper{
            align-items: center;
            justify-content: space-evenly;
            .contacts{
                display: flex;
                align-items: flex-start;
                justify-content: space-evenly;
                flex-wrap: wrap;
                padding-top: 80px;
                margin: 30px;
                .title{
                    width: 70%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    font-size: 30px;
                    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                    h1{
                        font-weight: 900;
                        color: #A4231C;
                    }
                }
                .info{
                    font-family: "Oswald", sans-serif;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 20px;
                    .email-and-phone{
                        display: flex;
                        gap: 100px;
                        width: 100%;
                        div{
                            font-size: 22px;
                            width: 100%;
                            color: #242222;
                            h4{
                                border-bottom: #A4231C 1px solid;
                            }
                        }
                    }
                }
            }
            .send-email{
                display: flex;
                flex-direction: column;
                z-index: 1;
                width: 80%;
                .title{
                    margin-left: 100px;
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    font-size: 30px;
                    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                    h1{
                        font-weight: 900;
                        color: #A4231C;
                    }
                }
                .form{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    padding: 10px;
                    margin-left: 180px;
                    .row{
                        input,textarea{
                            border: none;
                            border-bottom: 2px #A4231C solid;
                            margin: 20px;
                            font-size: 18px;
                            font-family: 'Courier New', Courier, monospace;
                            &:focus{
                                outline: none;
                            }
                        }
                        button{
                            width: 100px;
                            border: none;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            font-size: 14px;
                            transition: .5s ease-in-out;
                            cursor: pointer;
                            border: #ccc 1px solid;
                            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                            svg{
                                font-size: 17px;
                                color: #242222;
                                transition: .3s ease-in-out;
                            }
                            &:hover{
                                border: #242222 1px solid;
                                background-color: white;
                                color: #242222;
                                svg{
                                    color: #A4231C;
                                }
                            }
                        }
                    }
                }
                @media (max-width: 768px) { // Adjust the max-width value as needed for your target mobile devices
                    .form{
                        margin-left: 0px;
                    }
                    .title{
                        margin-left: 0px;
                    }
                    }
        
                
            }
        }
    }
}
@media (max-width:900px){
    .contactUs{
        .contact-us-background{
            .image-3{
                img{
                    width: 30vw;
                }
            }
        }
        .content{
            .header{
                .info{
                    width: 90%;
                }
            }
            .contacts{
                .info{
                    .email-and-phone{
                        div{
                            margin: 30px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:500px){
        .contactUs{
            .content{
                margin-bottom: 0 !important;
                .header{
                    .info{
                        .desc{
                            margin-top: 70%;
                            p{
                                margin: 0 3%;
                                text-align: center;
                            }
                        }
                    }
                }
                .contacts{
                    .info{
                        .email-and-phone{
                            flex-wrap: wrap;
                            div{
                                margin: 0;
                            }
                        }
                    }
                }
                .send-email{
                    .title{
                        width: 100%;
                        align-items: center;
                    }
                    .form{
                        margin-left: 0;
                        .row{
                            textarea{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}