//mixins 
@mixin magic-border($width, $color, $duration, $direction){
    position:relative;
    &:before{
        content:'';
        position:absolute;
        width:calc(100% + #{$width * 2});
        height:calc(100% + #{$width * 2});
        top:calc(#{$width}/-1);
        left:calc(#{$width}/-1);
        background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
        background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
        background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
        background-repeat:no-repeat, no-repeat;
        transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
        transform:scaleX(0) rotate(180deg * $direction);
        transition-delay:$duration*2, $duration, 0s;
    }
    &:hover{
        &:before{
            background-size:100% $width, $width 200%, $width 400%, 55% $width, 55% $width;
            background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
            transform:scaleX(1) rotate(180deg * $direction);
            transition-delay:0s, $duration, $duration*2;
        }
    }
}

.topbar{
    z-index: 999;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
    align-items: center;
    height: 85px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.2rem;
    border-bottom: white 1px solid;
    transition: .5s ease-in-out;
    background: linear-gradient(120deg, #f2f1f1, #edecec, #e9e6e7);
    opacity: .86;
    &.scrolled{
        opacity: 1;
    }
    .menuBtn{
        position: absolute;
        right: 0%;
        top: 17px;
        display: none;
    }
    &.clickedMenuBtn{
        height: 250px !important;
        ul{
            flex-direction: column;
        }
    }
    .logo{
        img{
            height: 80px;
            cursor: pointer;
            opacity: 1;
        }
    }
    
    .navButtons{
        z-index: 999;
        width: 70%;
        height: 100%;
        ul{
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            margin: 0;
            a{
                text-decoration: none;
                color: #222;
            }
            li{
                @include magic-border(2px, rgb(41, 38, 38), 0.3s, 1);                
                cursor: pointer;
                height: 50px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .2s ease-in-out;
                text-decoration: none !important;
                padding: 5px;
                background-color: transparent;
                &:hover{
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;                    
                    border-radius: 5px;
                }
                .dropdown {
                    display: none;
                    position: absolute;
                    top: 50px;
                    left: 0;
                    background-color: white;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                    z-index: 1;
                    li {
                        padding: 10px;
                        white-space: nowrap;
                        a {
                            color: black;
                            text-decoration: none;
                            &:hover {
                                background-color: #f1f1f1;
                            }
                        }
                    }
                }
                &:hover .dropdown {
                    display: block;
                }
            }
        }
    }
}

@media (max-width:810px){
    .topbar{
        z-index: 999;
        justify-content: start;
        flex-direction: column;
        &.clickedMenuBtn{
            height: 450px !important;
        }
        .logo{
            img{
                margin-bottom: 16%;
            }
        }
        .navButtons{
            ul{
                flex-direction: column;
            }
        }
        .menuBtn{
            display: block;
            margin-right: 20px;
            .theMenuBtn{
                height: 40px;
                width: 40px;
                cursor: pointer;
            }
        }
    }
    @media (max-width:450px){
        .topbar{
            z-index: 999;
            .logo{
                img{
                    margin-bottom: 35%;
                    height: 50px;
                    margin-top: 14px;
                }
            }
        }       
    }
}
