.service-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
    z-index: 99;
    h1{
        font-family: "Roboto",sans-serif;
        color: rgb(27, 26, 26);
        border-left: #c78800 3px solid;
    }
    .images-wrapper{
        background-color: #fff;
        border-radius: 20px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        
        .info-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60%;
            margin-left: 5%;
            .description{
                margin: 5% 0;
                .desc-text{
                    font-size: 25px;
                    
                    p{
                        margin: 5px;
                        font-family: "Roboto",sans-serif;
                        b{
                            color:#c78800;
                        }
                    }
                }
            }
        }
        .main-image{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px;
            width: 100%;
            img{
            object-fit: cover;
             height: 400px !important;
             width: 100%;
             border-radius: 10px;
            }
        }
        margin-bottom: 10px;
        .images{
            width: 100% !important;
            height: 100px !important;
            max-width: 100% !important;
            overflow: hidden;
            .swiper{
                width: 100% !important;
                height: 100px !important;
                max-width: 100% !important;
                .image{
                    height: 100px;
                    width: 200px;
                    object-fit: cover;
                    margin-right: 10px !important;
                    cursor: pointer;
                }
            }
        }
    }
    @media (max-width: 768px) { // Adjust the max-width value as needed for your target mobile devices
        .images-wrapper{
            flex-direction: column;
        }
        }

    .service-informations{
        .info-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 40px;
            margin-top: 20%;
            .information{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 20px;
                font-family: "Roboto",sans-serif;
                justify-content: center;
                margin-left: 20px;
                .categ{
                    color: rgb(0, 140, 221);
                    cursor: pointer;
                }
                span{
                    margin: 5px 0; 
                }
            }

        }

    }
    .produits-similaires{
        .produits-similaires-titre{
            margin-left: 20px;
            h1{
                font-family: "Roboto",sans-serif;
                color: #c78800;
                padding-left: 20px;
            }
        }
        .elements{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .swiper{
                max-width: 90vw;
                display: flex;
                width: 90vw;
                .swiper-button-next,.swiper-button-prev{
                    color: #c78800;
                }
                .element{
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin: 20px ;
                    background-color: #f1f1f1;
                    width: 400px;
                    height: 500px;
                    .elt_data{
                        width: 100%;
                        height: 100%;
                        
                        img{
                            width: 100%;
                            height: 60%;
                            object-fit: cover;
                        }
                        p{
                            font-size: 16px;
                            color: #555;
                            padding: 10px;

                        }
                    }
                    .lire-la-suite{
                        opacity: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        background-color: rgba(0,0,0,1) ;
                        height: 100%;
                        width: 100%;
                        transition: .4s ease-in-out;
                    }
                    button{
                        height: 50px;
                        width: 180px;
                        cursor: pointer;
                        background-color: #fff;
                        border-radius: 5px;
                        border: none;
                        position: absolute;
                        z-index: 100;
                        opacity: 0;
                        transition: .4s ease-in-out;
                    }
                    &:hover{
                        .lire-la-suite{
                            opacity: .5;
                        }
                        button {
                            opacity: 1 !important;
                            &:hover{
                                background: transparent;
                                color: white;
                                border: white 1px solid;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:1500px){
    .service-desc{
        margin-bottom: 10vh;
        padding-top: 10vh;
    }
    @media (max-width:900px){
        .service-desc{
            margin-top: -2vh;
            .produits-similaires{
                .elements{
                    .swiper{
                        .element{
                            margin-left: 23px; 
                        }
                    }
                }
            }
        }
        @media (max-width:400px){
            .service-desc{
                width: 101vw;
                padding-top: 30%;
                .service-informations{
                    .info-wrapper{
                        .image{
                            img{
                                width: 79vw;
                            }
                        }
                    }
                    .description{
                        .desc-text{
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
        
}